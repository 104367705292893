import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Staff from "../components/staff"
import akaash from "../images/akaash.jpg"
import jon from "../images/jon.jpg"
import tam from "../images/tamaryn.jpg"
import irene from "../images/irene.jpg"

const OurTeam = () => (
  <Layout linkColor="#5388ff">
    <SEO title="Our team" />
    <div className="paddedBody">
    <h1>Our team</h1>
    <div className="staffList">
    <Staff heading="Akaash Mukherjee  - Director and Partner"
    body='Akaash is passionate about ideas that drive innovation. After obtaining his Bachelor of Engineering from McGill University, he joined Jonar and became one of the original architects of their revolutionary software, ParagonERP. Over the past 7 years, he has worn many multidisciplinary hats, acting as Programmer, Director of Technology and Team Leader. Today, he continues to work with Jonar in a pioneering new role: as the Director of the innovation incubator, Jonar Labs. His mission is to shape the technological landscape of tomorrow.'
    image={akaash} />
    <Staff heading="Jon Ruby - Founding Partner"
    body='Jon displays a rare intersection of technological expertise and business acumen, which allows for the creative application of new technologies to real-world challenges. Passionate about user experience and design, Jon works to enrich the day-to-day lives of users by improving their experience with business technology. In the late 1990s, he helped found a company called Control-F1, a pioneer in the eSupport space. He went on to manage small companies in investment banking consulting, private equity for natural resources and medical devices respectively. As CEO at Jonar, Jon and his team have designed, built, and launched a new ERP system called ParagonERP that aims to revolutionize the industry.'
    image={jon} />
    <Staff heading="Tamaryn de Kock - Partner"
    body='Tamaryn wants to know what you think and how you think. Her natural curiosity led her to pursue a Bachelor of Science in Psychology at McGill University. After graduating, she took a job as a Marketing Intern at Jonar, with little idea of what she wanted to do or where her career might go. During that time she discovered her passion for understanding consumer behavior and applying it in the fields of marketing and human resources. As Chief of Staff, Tamaryn is now helping to shape the future of Jonar and Jonar Labs.'
    image={tam} />
    <Staff heading="Irene de Gooyer-Collins - Partner"
    body='Irene is a seasoned business leader with extensive experience in consumer products, wholesale distribution, manufacturing and software. She is currently the Chief Financial Officer for Wonderment Ventures, which provides capital and expertise to a select group of start-ups across a variety of consumer sectors. She joined the board of York Angel Investors in 2016 and is actively involved in the entrepreneurial community. Irene also acts as the Chief Alliance Officer at Jonar and as a partner in Jonar Labs.'
    image={irene} />
    </div>
  </div>
  </Layout>
)

export default OurTeam
